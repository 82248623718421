<template>
    <div class="r-modal">
        <div class="r-box"> 
            <slot name="header">
                <div class="r-header">
                    <h1>{{label}}</h1>
                </div>
            </slot>
            <div class="r-body">
                <slot></slot>
            </div>
            <div class="r-footer">
                <div class="r-center-flex r-ptb-10 r-jc-center">
                    <slot name="actionButton"></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  props: {
      label: {
          default: null
      }
  }
}
</script>