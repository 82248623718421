<template>
    <Modal label="Balas Pesan">
        <InputCustom
            v-model="item.username"
            :error="error.username"
            :errorMsg="errorMsg.username"
            @onFocus="resetError('username')"
            placeholder="Username"
            label="Username"
            readonly
        >
        
        </InputCustom>

        <InputCustom
            v-model="item.judul_memo"
            :error="error.judul_memo"
            :errorMsg="errorMsg.judul_memo"
            @onFocus="resetError('judul_memo')"
            placeholder="Judul"
            label="Judul"
            readonly
        >
        
        </InputCustom>
        
        <TextareaCustom
            v-model="item.isi_memo" 
            :error="error.isi_memo"
            :errorMsg="errorMsg.isi_memo"
            @onFocus="resetError('isi_memo')"
            placeholder="Jumlah Bonus"
            label="Jumlah Bonus"
        >
        </TextareaCustom>

        <TextareaCustom
            v-model="item.isi_reply" 
            :error="error.isi_reply"
            :errorMsg="errorMsg.isi_reply"
            @onFocus="resetError('isi_reply')"
            placeholder="Balas"
            label="Balas"
        >
        </TextareaCustom>
        
        <template #actionButton>
            <ButtonCustom
                :disabled="loading"
                type="secondary"
                size="sm"
                class="r-mlr-10"
                @click.prevent="resetData()"
            >
                Batal
            </ButtonCustom>
            
            <ButtonCustom
                :disabled="loading"
                type="primary"
                size="sm"
                class="r-mlr-10"
                @click.prevent="sendData()"
            >
                Balas
            </ButtonCustom>
        </template>
    </Modal>
</template>
<script>
import common from '@/mixins/function/common'
import { mapGetters, mapActions } from 'vuex'
import Modal from '@/components/Helper/Modal'
import validation from '@/mixins/function/validation'
import TextareaCustom from '@/components/Helper/Textarea'
export default {
    mixins: [
        common,
        validation
    ],
    components: {
        Modal,
        TextareaCustom
    },
    mounted() {
    },
    props: {
        inputType: {
            require: true,
        }
    },
    computed: {
        ...mapGetters({
            item: 'pesan/getItem',
        })
    },
    data: () => ({
        loadBanks: false,
        error: {
            isi_reply: null,
        },
        errorMsg: {
            isi_reply: 'Tidak boleh kosong',
        },
        loading: false,
    }),
    methods: {
        ...mapActions({
            store: 'pesan/store',
        }),
        resetData() {
            this.$emit('resetData')
        },
        validation() {
            var error = 0
            var field = ['isi_reply']
            for (var i = 0; i < field.length; i++){
                if(this.item[field[i]] === null || this.item[field[i]]  === ''){
                    this.error[field[i]] = true
                    error += 1;
                }else{
                    this.error[field[i]] = false
                }
            }
            return error
        },
        async sendData() {
            if (this.validation() === 0) {
                this.loading = true
                await this.store(this.item)
                this.loading = false
                const response = this.getResponse('pesan')
                this.showSnackbar({
                    type: response.status == 1 ? 'success' : 'error',
                    text: response.msg
                })

                if (response.status === 1) {
                    this.resetData()
                    this.$emit('getData')
                }
            } else {
                this.showSnackbar ({
                    type: 'error',
                    text: 'Mohon periksa kembali'
                })
            }
        },
    }
}
</script>