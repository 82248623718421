<template>
    <div>
        <TableCustom 
            :columns="columns" 
            :request="request" 
            :fetchData="fetchData"
            :data="data"
            @setSortBy="setSortBy"
            @setShow="setShow" 
            @getData="getData"
            @mouseleave="popup = null"
            @togglePopup="togglePopup"
        > 
            <template #index="props">
                {{curNumber(props.index)}}
            </template>

            <template #id_memo="props">
                {{props.row.id_memo}}
            </template>

            <template #judul_memo="props">
                {{props.row.judul_memo}}
            </template>

            <template #isi_memo="props">
                {{props.row.isi_memo}}
            </template>
            
            <template #role_name="props">
                {{props.row.role_name}}
            </template>
            
            <template #action="props">
                <ButtonCustom
                    type="primary"
                    :disabled="statusLoading != null"
                    @click.prevent="setData(props.row)"
                >
                    <span>
                        Balas
                    </span>
                </ButtonCustom>
            </template>
        </TableCustom>

        <InputModal
            v-if="modal == 'balasModal'"
            :inputType="inputType"
            @resetData="resetData"
            @getData="getData"
        >
        
        </InputModal>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import common from '@/mixins/function/common'
import tableMixins from '@/mixins/tableMixins'
import InputModal from '@/components/Pesan/InputModal'
export default {
    mixins: [
        common, 
        tableMixins
    ],
    components: {
        InputModal,
    },
    mounted() {
        this.initData();
    },
    computed: {
        ...mapGetters ({
            data: 'pesan/getData',
            request: 'pesan/getRequest',
            userStatus: 'pesan/getUserStatus',
        }),

    },
    data: () => ({
        columns: [
            {
                label: 'No',
                isSortable: false,
                fieldName: 'index',
                class: 'r-table-firstColumn'
            },
            {
                label: 'Username',
                isSortable: true,
                fieldName: 'username',
                class: ''
            },
            {
                label: 'Nomor Pesan',
                isSortable: true,
                fieldName: 'id_memo',
                class: ''
            },
            {
                label: 'Judul',
                isSortable: false,
                fieldName: 'judul_memo',
                class: ''
            },
            {
                label: 'Isi',
                isSortable: false,
                fieldName: 'isi_memo',
                class: ''
            },
            {
                label: 'Action',
                isSortable: false,
                fieldName: 'action',
                class: 'r-table-actionColumn'
            }
        ],
        modal: null,
        inputType: null,
        statusLoading: null,
    }),
    methods: {
        ...mapActions ({
            resetItem: 'pesan/resetItem',
            resetRequest: 'pesan/resetRequest',
            setItem: 'pesan/setItem',
            getPesan: 'pesan/fetchData'
        }),
        openModal(type = 'Tambah') {
            this.modal = 'balasModal'
            this.inputType = type
        },
        setData(item) {
            this.setItem(item)
            this.openModal('Balas')
        },
        resetData() {
            this.modal = null
            this.statusLoading = null
            this.resetItem()
            this.resetRequest()
        },
        async getData(type = 0) {
            this.fetchData = true
            if (type === 0) {
                this.request.page = 1
            } else {
                this.request.page += type
            }
            await this.getPesan()
            this.fetchData = false
            const response = this.getResponse('pesan')
            if (response.status !==1) {
                this.showSnackbar ({
                    type: 'error',
                    text: response.msg
                })
            }
        },
        async initData() {
            const breadcrumb = [{
                name: 'Pesan Pengguna',
                link: {name: null},
                current: true
            }]
            this.setActiveTab('Pengguna')
            this.setBreadCrumb(breadcrumb)
            await this.getData()
        },
    }
}
</script>